// Dependencies
import React, { useState, useEffect, useRef } from "react"
import { AnimatePresence, motion } from 'framer-motion'
// Hooks & Helpers
import useMediaQuery from "../hooks/useMediaQuery"
// Wrappers
import Layout from "../wrappers/Layout"
import { useStoreContext } from '../wrappers/Store'
// Components
import Image from "../components/Image"
import { ProductInfo, useProductInfoWhenReady } from "../components/MenuProductInfo"

// const ProductGallery = ({ images }) => {

// 	const [current, set] = useState(0)

// 	const handleClick = () => {

// 		set(prev => {
// 			return prev < (images.length - 1) ? prev + 1 : 0
// 		})
// 	}

// 	return (
// 		<div className="product__gallery__mobile">
// 			<div className="bg--tan" onClick={handleClick}>
// 				{images && images[current] && <Image image={images[current].large} />}
// 			</div>

// 			<div className="gallery__thumbnails">
// 				{images?.map((image, index) => (
// 					<div className={`thumbnail`} onClick={() => set(index)} style={{ opacity: index !== current ? 1 : 0.1 }} key={image.id} >
// 						<Image image={image.medium} proHeight />
// 					</div>
// 				))}
// 			</div>
			
// 		</div>
// 	)
// }

function useLockBodyScroll(ref, flag) {

	const scrollRef = useRef()

	useEffect(() => {

		const $body = document.querySelector('body')

		function preventDefault(e) {
			e.preventDefault();
		}

		function showModal() {
			// remember scroll position
  		scrollRef.current = window.scrollY
			// console.log(window.scrollY)
			// window.scrollTo(0, scrollRef.current);
			// Lock body scroll
			$body.classList.add("is-locked");
			// block pointer events
			if (ref.current) {
				ref.current.addEventListener("pointermove", preventDefault);
			}
		}

		function hideModal() {
			$body.classList.remove("is-locked");
			// resume pointer events
			if (ref.current) {
				ref.current.removeEventListener("pointermove", preventDefault);
			}
			// restore scroll position
  		window.scrollTo(0, scrollRef.current);
		}

		if (flag) {
			showModal()
		} else {
			hideModal()
		}

		return () => {
			// hideModal()
		}
	}, [flag])
}

const Zoom = ({ hires, setKey, ...props }) => {

	const ref = useRef()
	const [zoom, setZoom] = useState(false)
	const [position, setPosition] = useState({})

	const handleClick = () => {
		const { y, x, width, height } = ref.current.getBoundingClientRect()
		setPosition({ top: y, left: x, width, height, scale: 1 })
		setZoom(o => !o)
	}

	useLockBodyScroll(ref, zoom)

	return (
		<div 
			className={`image-zoom ${zoom ? 'open' : ''}`} 
			ref={ref} 
			onClick={handleClick}
		>
			<Image {...props} />
			<AnimatePresence key={setKey}>
				{zoom && <ZoomBackground key={`${setKey}bg`} />}
				{zoom && <ZoomImage position={position} {...props} key={`${setKey}img`} />}
				{zoom && (
					<div className="image-zoom-close pfix t0 r0 z-zoom-top" key={`${setKey}close`}>
						<button type="button" className="btn--bold--invert active">CLOSE</button>
					</div>
				)}
			</AnimatePresence>
		</div>
	)
}

const ZoomBackground = () => {
	const initial = {
		opacity: 0
	}
	return (
		<motion.div 
			className="image-zoom-background" 
			initial={{
				...initial
			}}
			animate={{
				opacity: 1
			}}
			exit={{
				...initial
			}}
		/>
	)
}

const ZoomImage = ({ position, ...props }) => {

	return (
		<motion.div 
			className="image-zoom-wrapper"
			initial={position}
			animate={{
				top: 0,
				left: 0,
				width: '100%',
				height: '100vh', // var(--app-height)
				scale: 1
			}}
			exit={position}
			transition={{
				ease: 'easeInOut'
			}}
		>
			<Image {...props} proHeight />
		</motion.div>
	)
}

const ProductTemplate = ({ pageContext }) => {

	const { currentProduct } = useStoreContext()
	const liveProduct = currentProduct || pageContext.currentProduct
	const { title, images, description } = liveProduct
	const ready = useProductInfoWhenReady(currentProduct)
	
	const shareImage = liveProduct?.images[0]?.src

	// const isMobile = useMediaQuery('(orientation: portrait)', true)

  return (
    <Layout title={title} description={description} shareImage={shareImage}>

			{/* Shows on portait/mobile devices */}
			{/* {isMobile && (
				<section className="product__view mobile">
					<ProductGallery images={images} />
					<div className="innerx2 guttersx4">
						{ready && <ProductInfo {...liveProduct} />}
					</div>
				</section>
			)} */}

			{/* Shows on desktop */}
			<section className="product__view">
				<div className="span-6 start-7">
					<div className="product__view__gallery">
						<div className="product__view__gallery__inner">
							{images?.map((image, index) => (
								<Zoom image={image.large} hires={image.hires} setKey={`zoom-image-${index}`} key={`zoom-image-${index}`} />
							))}
						</div>
					</div>
					<div className="innerx2 guttersx4 mobile">
						{ready && <ProductInfo {...liveProduct} />}
					</div>
				</div>
			</section>

    </Layout>
  )
}

// ProductTemplate.propTypes = {
//   data: PropTypes.object.isRequired
// }

export default ProductTemplate

// export const ProductTemplatePageQuery = graphql`
//   query ProductTemplateQuery($shopifyId: String!) {
//     contentfulProduct(shopifyId: {eq: $shopifyId}) {
//       title
//     }
// 		shopifyProduct(variants: {elemMatch: {storefrontId: {eq: $shopifyId}}}) {
// 			title
// 			images {
// 				id
// 				gatsbyImageData(
// 					layout: FULL_WIDTH
// 				)
// 			}
// 			variants {
// 				price
// 				selectedOptions {
// 					name
// 					value
// 				}
// 				storefrontId
// 				availableForSale
// 				compareAtPrice
// 			}
// 		}
//   }
// `
